export const poolColor = [
  '#E2B5D3', //(Lavender)
  '#FFDAB9', //(Papaya Whip)
  '#C1E1C5', //(Mint Green)
  '#F1C3D0', //(Pale Pink)
  '#FFE5B4', //(Peach)
  '#E6E6FA', //(Lavender Mist)
  '#FFC0CB', //(Pink)
  '#FFFACD', //(LemonChiffon)
  '#F0E68C', //(Khaki)
  '#98FB98', //(Pale Green)
  '#ADD8E6', //(Light Blue)
  '#FFDAB9', //(Peach Puff)
  '#D3D3D3', //(Light Gray)
  '#FFF0F5', //(Lavender Blush)
  '#F0FFF0', //(Honeydew)
  '#F4CCFF', //(Lilac)
];

export const getMatchPos = (matchKey: string) => matchKey.replace('R', '').replace('M', ',').split(',')?.map(Number);

export const minOne = (val: number) => (val <= 0 ? 1 : val);
export const minZero = (val: number) => (val <= 0 ? 0 : val);
